import { Box, Text } from '@chakra-ui/react';

export default () => {
	return (
		<Box mt={ 4 }>
			<Text textAlign="center" fontSize="xs" color="gray.600">
				v03.12.2024
			</Text>
		</Box>
	);
};
